<template>
  <div class="list js_industry_list">
    <Introduce :moduleId="6"/>
    <p class="title industry_title">产业课堂</p>
    <div class="list_box">
      <ul class="list_ul" v-if="list.length>0">
        <li class="list_li" v-for="item in list" :key="item.moduleId">
          <router-link :to="`/industryClass/details?id=${item.moduleId}&moduleType=${modules.moduleType}`"
                       target="_blank" class="list_link vishide animate__animated">
            <div class="list_img_box">
              <img class="list_img" :src="utils.setOssUrl(item.picture)" alt="">
            </div>
            <p class="list_tilte">{{ item.title }}</p>
            <p class="list_desc">{{ item.digest }}</p>
            <p class="list_detail">
              <dj-button>了解详情</dj-button>
            </p>
          </router-link>
        </li>
      </ul>
      <Nothing v-else class="nothing"/>
    </div>
    <dj-paging :pageNumber="page.pageNo" :pageSize="page.pageSize" :total="page.total" :top="top"
               @current-change="handleCurrentChange"/>
  </div>
</template>

<script>
import {getCaseList} from "@/api/product.js";
import module from "@/const/module";
import $ from "jquery";
import utils from "@/utils/utils";
import metaInfoMixins from "@/utils/mixin/metaInfoMixins";

export default {
  name: "list",
  mixins: [metaInfoMixins],
  data() {
    return {
      page: {
        pageNo: 1,
        pageSize: 6,
        total: 0
      },
      list: [],
      modules: module.industryClass,
      top: 0,
      utils
    };
  },
  created() {
    this.getCaseListFun();
  },
  mounted() {
    this.getListTop();
    this.$nextTick(() => {
      this.handleScroll();
      window.addEventListener('scroll', this.handleScroll);
      window.onresize = () => {
        this.removeVishide();
      };
    });
  },
  methods: {
    getListTop() {
      this.$nextTick(() => {
        const el = $(".js_industry_list");
        let top = el.find(".industry_title").offset().top;
        this.top = top;
        window.onresize = () => {
          let top = el.find(".industry_title").offset().top;
          this.top = top;
        };
      });
    },
    handleCurrentChange(page) {
      this.page.pageNo = page;
      this.getCaseListFun();
    },
    getCaseListFun() {
      let params = {
        configType: this.modules.moduleType,
        ...this.page
      };
      getCaseList(params).then((res) => {
        if (res.success) {
          this.list = res.data.caseList;
          this.page.total = res.data.count;
          this.$nextTick(() => {
            this.handleScroll();
            this.removeVishide();
          });
        }
      });
    },
    handleScroll() {
      utils.handleScroll($(".js_industry_list .list_li"), (i, el) => {
        setTimeout(() => {
          el.children(".list_link").removeClass("vishide").addClass("animate__slideInUp").removeClass("animate__slideOutDown");
        }, i * 100);
      }, (i, el) => {
        setTimeout(() => {
          el.children(".list_link").removeClass("animate__slideInUp").addClass("animate__slideOutDown");
        }, i * 100);
      });
    },
    removeVishide() {
      let w = $(window).width();
      if (w < 960) {
        $(".js_industry_list .list_link").removeClass("vishide");
      } else {
        $(".js_industry_list .list_link").addClass("vishide");
      }
    }
  }
};
</script>

<style scoped lang="scss">
.list {
  overflow: hidden;
  .title {
    font-size: 40px;
    color: #333333;
    line-height: 56px;
    margin: 60px 0 41px 0;
    text-align: center;
    font-weight: bold;
  }
  .list_box {
    padding: 0 60px;
    .list_ul {
      .list_li {
        margin-top: 25px;
        &:first-child {
          margin-top: 0;
        }
        .list_link {
          background-color: #FFFFFF;
          display: flex;
          flex-direction: column;
          .list_img {
            width: 100%;
            height: 354px;
          }
          .list_tilte {
            font-size: 26px;
            color: #252931;
            line-height: 37px;
            padding: 24px 24px 16px 24px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .list_desc {
            font-size: 23px;
            color: #666666;
            line-height: 32px;
            padding: 0 24px;
            max-height: 96px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
          }
          .list_detail {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 24px 0;
            a {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 296px;
              height: 73px;
              background: #0064EB;
              font-size: 24px;
              color: #FFFFFF;
            }
          }
        }
      }
    }
    .nothing {
      padding-bottom: 60px;
    }
  }
}
@media (min-width: 751px) and (max-width: 960px) {
  .list {
    .title {
      font-size: 32px;
      color: #333333;
      line-height: 45px;
      margin: 60px 0;
      text-align: center;
    }
    .list_box {
      width: 100%;
      margin: 0 auto;
      padding: 0;
      .list_ul {
        display: flex;
        flex-wrap: wrap;
        padding: 0 60px;
        .list_li {
          width: 408px;
          margin-top: 25px;
          margin-left: 24px;
          &:nth-child(2n+1) {
            margin-left: 0;
          }
          &:nth-child(1) {
            margin-top: 0;
          }
          &:nth-child(2) {
            margin-top: 0;
          }
          .list_link {
            background-color: #FFFFFF;
            display: flex;
            flex-direction: column;
            .list_img {
              height: 230px;
            }
            .list_tilte {
              font-size: 18px;
              color: #333333;
              line-height: 25px;
              padding: 16px 24px 10px 24px;
            }
            .list_desc {
              font-size: 16px;
              color: #666666;
              line-height: 22px;
              height: 66px;
            }
            .list_detail {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 24px 0 30px 0;
              a {
                width: 156px;
                height: 44px;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 961px) {
  .list {
    .title {
      font-size: 32px;
      color: #333333;
      line-height: 45px;
      margin: 60px 0 40px 0;
      text-align: center;
    }
    .list_box {
      width: 1200px;
      margin: 0 auto;
      padding: 0;
      .list_ul {
        display: flex;
        flex-wrap: wrap;
        .list_li {
          width: 383px;
          margin-top: 24px;
          margin-left: 25px;
          &:nth-child(3n+1) {
            margin-left: 0;
          }
          &:nth-child(1) {
            margin-top: 0;
          }
          &:nth-child(2) {
            margin-top: 0;
          }
          &:nth-child(3) {
            margin-top: 0;
          }
          .list_link {
            background-color: #FFFFFF;
            display: flex;
            flex-direction: column;
            &:hover{
              box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.07);
              .list_img {
                transform: scale(1.1);
              }
              .list_tilte{
                color: #0064EB;
              }
            }
            .list_img_box{
              overflow: hidden;
            }
            .list_img {
              width: 100%;
              height: 216px;
              transition: transform .5s;
            }
            .list_tilte {
              font-size: 18px;
              color: #333333;
              line-height: 25px;
              padding: 24px 24px 12px 24px;
            }
            .list_desc {
              font-size: 16px;
              color: #666666;
              line-height: 22px;
              height: 66px;
            }
            .list_detail {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 24px 0 20px 0;
              a {
                width: 120px;
                height: 42px;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
